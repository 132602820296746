.active-link {
    color: #fda726;
    text-decoration: none;
    align-self: flex-start;
    padding: 5px;
}

.myLink {
    color: #657d86;
    text-decoration: none;
    align-self: flex-start;
    padding: 5px;
  }

