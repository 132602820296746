.transform-card{
    position: "fixed";
    bottom: "0px";
    left: "50%";
    max-width:'450px';
    z-Index: 1;
    transform: translateX(50%);
  }

  .card-content{
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: "3%",
  }

  .card-media-services{
	width: 30%;
  }
  .card-text{
	width: 80%;
	display: flex;
	flex-direction: column;
	gap: 2%
  }

  @media (max-width: 800px) {
	.card-content {
	  flex-direction: column;
	}
	.card-media {
		width: 80%;
	}
	.card-media-services {
		width: 80%;
	}
	.card-text {
		width: 100;
	}
  }

  .info{
		position: relative;
		z-index: 3;
		color: white;
		opacity: 0;
		transform: translateY(30px);
		transition: 0.5s;
		.h1{
			margin: 0px;}
		.p{
			letter-spacing: 1px;
			font-size: 15px;
			margin-top: 8px;
        }
		.button{
			padding: 0.6rem;
			outline: none;
			border: none;
			border-radius: 3px;
			background: white;
			color: black;
			font-weight: bold;
			cursor: pointer;
			transition: 0.4s ease;
			&:hover{
				background: dodgerblue;
				color: white;}}
  }