.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .slide-item{
    width: 100%;
    height: 470px;
  }

  @media screen and (max-width: 1024px) {
    .slide-item {
      scale: 1;
  }
  }