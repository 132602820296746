.alert-enter {
    transform: translateX(-200%);
    transform-origin: 0%;
  }
  .alert-enter-active {
    transform: translateX(0);
    transition: transform 800ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
  .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 5px;
  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role="tooltip"].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup="tooltip"].popup-overlay {
    background: transparent;
  }
  
  .popup-content{
      font-size: 16px;
      padding: 20px;
  } 
