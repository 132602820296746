.card {
    margin-top: 20px;
    justify-content: "center";
    display: "flex";
    flex-direction: "column";
    border-radius: 10px;
    .card-button{
        display: none;
    }
  }
  
  .card .card-link {
    display: none;
  }
  
  .card-button {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(121, 121, 123);
    text-decoration: none;
    color: bisque;
    border: none;
    border-radius:"5%";

  }

  .card-link{
    text-decoration: none;
    border:none;
    background-color: burlywood;
    color: white;
    width: 100%;
    margin: auto;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border-radius: 5px;
    padding-top: 5%;
    padding-bottom: 5%;
  }

.card-media{
  opacity: 0.7;
}

.card-link:hover {
    background-color: #657d86;
    color: whitesmoke
}
  
  
  .card:hover .card-media{
    opacity: 1;
    background-color: #657d86;
  }

  .card:hover .card-link {
    display: block;
  }

  .agent-title-bg {
    width: 100%;
    margin-bottom: 15%;
  }