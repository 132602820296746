body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-style {
  height: 55px;
  margin: auto;
  align-self: flex-start;
}

.img-style {
  width: auto;
  height: auto;
}

.myLink {
  color: whitesmoke;
  text-decoration: none;
}

.myLink-content {
  color: #cd7700;
  text-decoration: none;
  align-self: baseline;
}


.h1 {
  color:#272d2f;
  padding: 5px;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.myLinkFooter {
  margin-top: 10px;
  color: whitesmoke;
  text-decoration: none;
}

.underLine {
  background-color:rgba(0, 51, 161, 0.2);
  width: 100px;
  height: 2px;
  border-radius: 2px;
  align-self: center;
  margin-bottom: 5%;
}

@media screen and (max-width: 1080px) {
  .contacts-image {
      display: none !important;
  }
  .contacts-icon {
    display: block;
}
  .numbers {
    flex-direction: column; 
}
  .orderStyle {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width:"50%"
  }

  .orderTitle{
    width: 350px;
  }
  
  .orderStyleSelect {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
    align-items: center;
  }
  .orderImg {
    scale: 0.7;
  }
}

@media screen and (max-width: 1024px) {
  .contacts-icon {
    display: none !important;
}
}

@media (min-width:800px) and (max-width: 5000px) {
  .orderStyleSelect {
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 50%;

  }
  
}

.numbers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

ul {
  align-self: flex-start;
}